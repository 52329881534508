<template>
  <b-row v-if="apporteur != false && params != false">
    <b-col md="10" offset-md="1">
      <div class="p-5">
        <p class="h5 text-dark">Apporteur d'affaire: {{apporteur.id}} - {{apporteur.raison_social}}  <b-button :variant="statusA.color" style="margin-left:15rem">{{statusA.titre}}</b-button></p>
        <b-card no-body>
          <form ref="form">
            <b-tabs card>
              <template #tabs-end>
                <b-dropdown class="ms-3 mb-2">
                  <template #button-content>
                    <i class="fas fa-thermometer-half"></i>
                  </template>
                  <b-dropdown-item v-for="(s,i) in params.ApporteurStatus" :key="i" :active="s.id === apporteur.status_id" @click="apporteur.status_id = s.id">{{s.titre}}</b-dropdown-item>
                </b-dropdown>
                <b-button variant="success" class="text-white ms-1 mb-2" @click="save()">
                  <span v-if="save_loader === false">Sauvegarder</span>
                  <b-spinner v-else type="grow" small></b-spinner>
                </b-button>
                <b-button v-if="apporteur.id != null && apporteur.status_id === 1" variant="primary" class="text-white ms-1 mb-2" @click="activation()">
                  <span>Activation</span>
                </b-button>
              </template>
              <b-tab title="Information generale" active>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Raison sociale:">
                      <b-form-input v-model="apporteur.raison_social" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="RCS:">
                      <b-form-input v-model="apporteur.rcs" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Nom:">
                      <b-form-input v-model="user.nom" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Prenom:">
                      <b-form-input v-model="user.prenom" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Email:">
                      <b-form-input v-model="user.email" type="email" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Telephone:">
                      <b-form-input v-model="user.telephone" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Mot de passe:">
                      <b-form-input v-model="user.password" placeholder="Laissez vide pour ne pas changer"></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group label="Adresse:">
                      <b-form-input v-model="apporteur.adresse" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Ville:">
                      <b-form-input v-model="apporteur.ville" required></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Code postal:">
                      <b-form-input v-model="apporteur.cp" required></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Convention:">
                      <b-input-group class="mt-3">
                        <input type="file" placeholder="Choisissez votre document" @change="handleFile" class="form-control"/>
                        <b-input-group-append>
                          <b-button variant="primary" v-if="apporteur.convention != null" :href="apporteur.convention" target="_blank"><i class="fas fa-eye"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Type de commission:">
                      <select class="form-control" v-model="apporteur.type_commission" required>
                        <option v-for="(c, i) in params.CommissionType" :value="c.id" :key="i"> {{c.titre}}</option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Commission Fixe:">
                      <b-input-group append="€">
                        <b-form-input v-model.number="apporteur.type_commission_fixe" type="number" required></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group label="Commission Lineaire:" v-if="apporteur.type_commission === 2">
                      <b-input-group append="%">
                        <b-form-input v-model.number="apporteur.type_commission_lineaire" type="number" required></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-tab>
              <b-tab title="Partenaires">
                <div v-for="(p,i) in partenaires" :key="i" class="mt-3">
                  <p class="h5 text-dark">{{p.raison_social}} - Date d'entrée {{$store.api.timestampToDate(p.date_create, false)}}</p>
                  <b-button variant="success" class="ms-2" :href="p.convention" target="_blank">Convention de partenariat</b-button>
                  <b-button variant="success" class="ms-5" :href="'/#/admin/partenaire/detail/'+p.id">Fiche partenaire</b-button>
                </div>
              </b-tab>
              <b-tab title="Commentaire" v-if="ifNew === false">
                <b-form-textarea v-model="apporteur.commentaire" rows="7" disabled id="commentaire_input"></b-form-textarea>
                <b-form-group label="Votre commentaire:">
                  <b-input-group>
                    <b-form-input v-model="commentaireTmp" @keyup.enter="addCommentaire()"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="success" @click="addCommentaire()">
                        <i class="fas fa-paper-plane"></i>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-tab>
              <b-tab title="Facturation" v-if="ifNew === false">
                <b-table-simple bordered hover small caption-top responsive>
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th>Periode mensuelle</b-th>
                      <b-th>Nombre d'inscription</b-th>
                      <b-th>Nombre de souscription</b-th>
                      <b-th>Montant a payer</b-th>
                      <b-th>Date de paiement</b-th>
                      <b-th>Statut paiement</b-th>
                      <b-th>Documents</b-th>
                      <b-th>Action</b-th>
                    </b-tr>
                  </b-thead>
                  <b-tbody class="text-center">
                    <b-tr v-for="(f,i) in factures" :key="i">
                      <b-td>{{formatDate(f.debut)}} - {{formatDate(f.fin)}}</b-td>
                      <b-td>{{f.nb_inscrit}}</b-td>
                      <b-td>{{f.nb_souscription}}</b-td>
                      <b-td>{{f.montant_ht.toFixed(2)}}</b-td>
                      <b-td><b-form-input type="date" v-model="f.date_paiement"></b-form-input></b-td>
                      <b-td>
                        <b-button v-if="f.status_id === 1" :variant="$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).color">{{$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).titre}}</b-button>
                        <b-dropdown v-else class="lang-dropdown" :variant="$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).color">
                          <template #button-content>
                            {{$store.api.getParam(params.SouscriptionFactureStatus, f.status_id).titre}}
                          </template>
                          <b-dropdown-item v-for="(s, i) in params.SouscriptionFactureStatus" :key="i" @click="f.status_id = s.id" :disabled="s.id === 1">{{s.titre}}</b-dropdown-item>
                        </b-dropdown>
                      </b-td>
                      <b-td>
                        <div v-if="f.status_id === 2">
                          <b-button size="sm" class="ms-1 text-white" variant="warning" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'preuve'" v-b-tooltip.hover title="Preuve de virement">P</b-button>
                          <b-button size="sm" class="ms-1 text-white" variant="warning" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'facture'" v-b-tooltip.hover title="Facture du apporteur">F</b-button>
                        </div>
                        <div v-if="f.status_id === 3">
                          <b-button size="sm" class="ms-1 text-white" variant="primary" :href="f.docs.preuve" target="_blank" v-b-tooltip.hover title="Preuve de virement">P</b-button>
                          <b-button size="sm" class="ms-1 text-white" variant="primary" :href="f.docs.facture" target="_blank" @click="$refs.uploadDoc.show(); doc_id = f.id; doc_key = 'facture'" v-b-tooltip.hover title="Facture du apporteur">F</b-button>
                        </div>
                      </b-td>
                      <b-td><b-button v-if="f.status_id != 1" @click="saveFac(f)" variant="success" size="sm"><i class="fas fa-save"></i></b-button></b-td>
                    </b-tr>
                  </b-tbody>
                  <b-tfoot class="text-center">
                    <b-tr>
                      <b-th></b-th>
                      <b-th>{{total_inscrit}}</b-th>
                      <b-th>{{total_souscriptions}}</b-th>
                      <b-th>{{total_ht}}</b-th>
                      <b-th></b-th>
                      <b-th></b-th>
                      <b-th></b-th>
                    </b-tr>
                  </b-tfoot>
                </b-table-simple>
              </b-tab>
              <b-tab title="Commissions" v-if="ifNew === false">
                <b-table-simple stacked="md" striped hover>
                  <b-thead>
                    <b-tr>
                      <b-th>Date</b-th>
                      <b-th>Type</b-th>
                      <b-th>Souscription</b-th>
                      <b-th>Montant HT</b-th>                      
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(c, i) in commissions" :key="i" :variant="c.montant_ht < 0 ? 'danger' : ''">                      
                      <b-td>{{$store.api.timestampToDate(c.date_create, true)}}</b-td>
                      <b-td>{{c.commission_type}}</b-td>
                      <b-td><a :href="`/#/admin/inscrit/${c.souscription_id}`">{{c.souscription_id}}</a></b-td>
                      <b-td>{{$store.api.price(c.montant_ht)}}€</b-td>                      
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-tab>
            </b-tabs>
          </form>
        </b-card>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: "",
  components: {
  },
  data(){
    return {
      save_loader: false,
      params: false,
      apporteur: false,
      user:{},
      factures:[],
      doc_id:null,
      doc_key:null,
      partenaires:[],
      commissions:[],
      commentaireTmp:null
    }
  },
  watch:{
    'apporteur.type_commission'(){
      if(this.apporteur.type_commission === 1){
        this.apporteur.type_commission_lineaire = null;
      }
    },
    'apporteur.status_id'(){
      this.user.status = this.apporteur.status_id === 1 ? 1 : 0;
    }
  },
  methods: {
    init(){
      this.$store.api.ajax('/apporteur/'+this.$route.params.id, null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.apporteur = res.data.apporteur;
          this.partenaires = res.data.partenaires;
          this.factures = res.data.factures;
          this.commissions = res.data.commissions;
        }
      })
    },
    addCommentaire(){
      if(this.apporteur.commentaire === null){
        this.apporteur.commentaire = "";
      }
      var date = new Date();
      var day = date.getDate() < 10 ? "0"+date.getDate() : date.getDate();
      var month = date.getMonth() < 10 ? '0'+(date.getMonth() + 1) : date.getMonth() + 1;
      var year = date.getFullYear();
      var hour = date.getHours() < 10 ? "0"+date.getHours() : date.getHours();
      var minute = date.getMinutes() < 10 ? "0"+date.getMinutes() : date.getMinutes();
      var txt = `${this.commentaireTmp} | par ${this.$store.api.user.prenom} ${this.$store.api.user.nom} le ${day}/${month}/${year} à ${hour}:${minute}`;
      this.apporteur.commentaire += txt;
      this.apporteur.commentaire += "\n";
      this.commentaireTmp = null;
      setTimeout(() => {
        var textarea = document.getElementById('commentaire_input');
        textarea.scrollTop = textarea.scrollHeight;
      },5);
    },
    checkForm(){
      var res = true;
      if(this.$refs.form.reportValidity() === false){
        res = false;
      }
      if(this.apporteur.convention === null){
        res = false;
      }
      return res;
    },
    save(){
      if(this.save_loader === true){
        return false;
      }
      if(this.checkForm() === false){
        this.$toastr.w(" Merci de remplir tout les champs");
        return false;
      }
      this.save_loader = true;
      var params = {
        apporteur: this.apporteur,
        user:this.user
      }
      this.$store.api.ajax('/apporteur/create', params, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.apporteur = res.data.apporteur;
          this.$toastr.s("Sauvegarder");
          this.$router.push({path:"/admin/apporteur/detail/"+this.apporteur.id});
        }
        this.save_loader = false;
      })
    },
    handleFile(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name
        }
        this.$store.api.ajax('/sinistre/doc', tmp, (res) => {
          if(res.status == true){
            this.apporteur.convention = res.data;
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    handleDoc(e){
      var files = e.target.files;
      const fileUploaded = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        var tmp = {
          'file': e.target.result,
          'file_name': fileUploaded.name,
          'doc_id':this.doc_id,
          'doc_key':this.doc_key
        }
        this.$store.api.ajax('/partenaire/doc', tmp, (res) => {
          if(res.status == true){
            this.doc_id = null;
            this.doc_key = null;
            this.init();
            this.$refs.uploadDoc.hide();
          }
        })
      }
      reader.readAsDataURL(fileUploaded);
    },
    formatDate(date){
      var tmp = date.split('-');
      if(tmp.length === 3){
        return tmp.reverse().join('/')
      }else{
        return '';
      }
    },
    saveFac(f){
      this.$store.api.ajax('/admin/facture', f, res => {
        if(res.status){
          f = res.data
        }
      })
    },
    activation(){
      var params = {
        model: "Apporteur",
        model_id: this.apporteur.id
      }
      this.$store.api.ajax('/partenaire/activation', params, res => {
        if(res.status === true){
          this.$toastr.s("Sauvegarder");
        }
      })
    }
  },
  computed:{
    ifNew(){
      return this.apporteur.id === null ? true : false;
    },
    statusA(){
      return this.$store.api.getParam(this.params.ApporteurStatus, this.apporteur.status_id);
    }
  },
  beforeMount() {
    this.$store.api.ajax('/apporteur/params', null, (res) => {
      if(res.status === true){
        this.params = res.data
      }
    });

  },
  mounted() {
    if(this.$route.params.id === undefined){
      this.$store.api.ajax('/apporteur/new', null, (res) => {
        if(res.status === true){
          this.user = res.data.user;
          this.apporteur = res.data.apporteur;
        }
      });
    }else{
      this.init()
    }
  }
}
</script>
<style media="screen">
.btn.dropdown-toggle {
  width: initial;
  height:initial;
}
</style>
